<template>
	<div class="block tu-faqs-right">
		<div class="input">
			<el-input
				v-model="search_text"
				class="w-50 m-2"
				size="large"
				placeholder="请输入搜索内容"
				input-style="border: 1px solid #000; border-radius: 9px; height: 50px;"
				@keyup.enter="onSearchSubmit">
			</el-input>
		</div>
		<!-- 推荐文章 -->
		<div class="item" v-if="lists.length > 0">
			<div class="title">推荐文章</div>
			<div class="content">
				<div class="bt" v-for="item, index in lists" :key="index">
					<div v-if="index < 3" class="dot dot-third" :style="{backgroundColor: index==0?'#F49B9B':(index==1?'#FEC49D':'#FFF2D0')}">
						{{ index + 1 }}
					</div>
					<div v-else class="dot"></div> 
					<el-link :underline="false" class="link" @click="handleDetail(index, item)">
						{{ item.title }}
					</el-link>
				</div>
			</div>
		</div>
		<div class="item" v-if="tags.length > 0">
			<div class="title">热门标签</div>
			<div class="content">
				<el-button size="small" round class="tag" v-for="tagItem, tagIndex in tags"
					@click="$router.push({path: '/faqs', query: {tag: tagItem}})">{{ tagItem }}</el-button>
			</div>
		</div>
		<div class="item" v-if="false">
			<div class="title">联系我们</div>
			<div class="content">
				<div class="image">
					<el-image style="width: 65%;height: 65%;" src="https://yiwise.com/_next/static/media/newscontact.88219979.png"></el-image>
				</div>
				<div class="phone">
					152-1005-6640
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
import FaqsApi from '@/api/faqs'
export default {
	name: 'FaqsRight',
	computed: {
	},
	data() {
		return {
			search_text: "",
			lists: [],
			tags: []
		}
	},
	created() {
		this.getApiListData();
	},
	methods: {		
		handleDetail(index, row) {
			let rowId = row.id || 0;
			if(!!!rowId) {
				this.$message.error(this.$t('exception.request'));
				return false;
			}
			this.$router.push({ path: `/faqs/${rowId}` })
		},
		getApiListData() {
			FaqsApi.index({hot: 1}).then((res) => {
				this.lists = res.data;
				this.lists.forEach((item, index) => {
					item.tags.forEach((tItem, tIndex) => {
						if(this.tags.indexOf(tItem) == -1) {
							this.tags.push(tItem);
						}
					})
				});
			});
		},
		onSearchSubmit() {
			if(!!this.search_text) {
				this.$router.push({path: "/faqs", query: {keyword: this.search_text}});
			}
			this.search_text = "";
			return false;
		}
	}
}
</script>
<style lang="less">
.tu-faqs-right {
	padding: 0px 20px;

	.item {
		border: 1px solid #000;
		border-radius: 12px;
		margin-top: 24px;
		padding: 16px 24px;

		.title {
			font-size: 24px;
			font-weight: 500;
			color: rgba(0,0,0,.85);
			background: transparent;
		}

		.content {
			margin-top: 12px;
			width: 100%;

			.bt {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				.dot {
					width: 6px; 
					height: 6px;
					display: inline-block; 
					border-radius: 50%;
					text-align: center;
					margin: 5px; 
					background-color: #000;
					flex-shrink: 0;

					&.dot-third {
						width: 16px;
						height: 16px;
						margin: 0;
					}
				}

				.link {
					font-weight: 200;
					margin-left: 10px;
					font-size: 16px;
					line-height: 2;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					line-clamp: 1;
					overflow: hidden;
					-webkit-box-orient: vertical;
				}
			}

			.tag {
				margin: 0 10px 10px 0;
			}

			.image {
				text-align: center;
			}
			.phone {
				text-align: center;
				font-weight: 600;
			    font-size: 28px;
			    margin-top: 5px;
			}
		}
	}
}
</style>