<template>
	<div id="contact-us">
		<div class="title">We're here to help!</div>
		<div class="desc">If you would like to get in touch, don't hesitate to start a chat or drop us a message by emailing us at <a href="mailto:support@tutoredin.com">support@tutoredin.com</a>. We'd love to hear from you.</div>
		<div class="date">Monday-Friday</div>
		<div class="time">9am - 5pm</div>
	</div>
</template>
<script>
export default {
	name: "contact-us",
	components: {
		
	},
	data() {
		return {
			
		}
	},
	created() {
		
	},
	methods: {
		
	}
}
</script>
<style lang="less">
#contact-us {
	padding: 80px 160px;

	.title {
		font-size: 3.5rem;
		font-weight: 900;
	}

	.desc {
		padding: 14px 0;
		font-size: 24px;
		line-height: 28px;
		font-weight: 300;

		a {
			color: var(--el-color-primary);
		}
	}

	.date {
		font-size: 24px;
		font-weight: 600;
		margin-top: 20px;
	}

	.time {
		font-size: 24px;
		font-weight: 500;
		margin-top: 10px;
	}
}
</style>