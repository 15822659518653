<template>
	<div id="tu-faqs-detail">
		<el-row :gutter="20">
			<el-col :span="16" class="left">
				<div class="warp" v-if="!!detail && !!detail.title">
					<div class="breadcrumb">
						<el-breadcrumb separator="/">
							<el-breadcrumb-item :to="{ path: '/faqs' }"> {{ $t('Faqs') }}</el-breadcrumb-item>
							<el-breadcrumb-item v-if="!!detail && !!detail.title">{{ detail.title }}</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="title">{{ detail.title }}</div>
					<div class="puttime">
						<span>view： {{ detail.view_num || 0 }}</span><span>time： {{ handleDateShow(detail.create_time) }}</span>
					</div>
					<div class="ql-snow">
						<div class="ql-editor" style="padding: 0;">
							<div v-html="detail.content"></div>
						</div>
					</div>
				</div>
				<div v-else>
					<el-skeleton />
				</div>
			</el-col>
			<el-col :span="8" class="right">
				<FaqsRight />
			</el-col>
		</el-row>
	</div>
</template>
<script>
import TimeControl from '@/utils/time-control';
import FaqsApi from '@/api/faqs';
import FaqsRight from './components/FaqsRight';

export default {
	name: "faqs-detail",
	components: {
		FaqsRight
	},
	data() {
		return {
			detail: {}
		}
	},
	created() {
		this.id = this.$route.params.id;
		this.getApiDetailData()
	},
	watch: {
		"$route.params.id": function() {
			if(this.id != this.$route.params.id) {
				this.id = this.$route.params.id;
				this.getApiDetailData();
			}
		}
	},
	methods: {
		getApiDetailData() {
			if(!!!this.id || /^\d+$/.test(this.id) === false) {
				// this.$message.error(this.$t('exception.request'))
				// this.$router.push({path: '/work'})
				return ;
			}
			FaqsApi.info(this.id).then((res) => {
				this.detail = res.data;
			});
		},
		handleDateShow(value) {
			return TimeControl.getFormatTime(value, 'YYYY-MM-DD')
		}
	}
}
</script>
<style lang="less">
#tu-faqs-detail {
	margin: 30px 0;

	.breadcrumb {
		padding: 20px 0;
	}
	
	.warp {
		padding: 15px 45px;

		.title {
			font-size: 32px;
			font-weight: 500;
		}

		.puttime {
			margin: 15px 0;
			color: var(--el-color-info);

			span {
				margin-right: 25px;
			}
		}
	}
}
</style>